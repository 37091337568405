
/* eslint-disable */
import JigTextField from '@/components/input/JigTextField.vue'
import GenericError from '@/components/snackbars/GenericError.vue'
import { ColorSet } from '@/components/statistics/constants'
import StatLineChart from '@/components/statistics/StatLineChart.vue'
import { Tenant } from '@/store/modules/app/types'
import { AppConst } from '@/store/modules/constants'
import {
  ChangeJigOwnerRequest,
  ChangeJigOwnerResult,
} from '@/store/modules/jig/types'
import {
  JigActivityChartData,
  JigSummaryStats,
  Namespace,
  ReportRequest,
} from '@/store/types'
import { ValidationRules } from '@/utils/input-validation'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

const Top5 = 5
const LookBackDays = 30
const ComparePreviousPeriod = true

const defaultActivityTrendChart: JigActivityChartData = {
  data: {
    labels: [],
    datasets: [
      {
        label: '',
        backgroundColor: '#f87979',
        data: [],
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    title: {
      display: true,
      text: 'Most popular Jigs in 30 days',
    },
    legend: {
      display: true,
    },
    tooltips: {
      enabled: true,
    },
  },
}

@Component({
  components: {
    StatLineChart,
    'generic-error': GenericError,
    'jig-text-field': JigTextField,
  },
})

export default class CommandCentre extends Vue {
  @State('jigDailyActivityData', { namespace: Namespace.Jig })
  public jigDailyActivityData!: JigSummaryStats[]
  @State('changeJigOwnerResult', { namespace: Namespace.Jig })
  public changeJigOwnerResult!: ChangeJigOwnerResult
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant

  @Action('executeStatisticReport', { namespace: Namespace.Jig })
  public executeStatisticReport: any
  @Action('changeJigOwner', { namespace: Namespace.Jig }) public changeJigOwner: any

  public isFetchingJigData: boolean = false
  public transferJigFormIsValid: boolean = false
  public jigHashIds: string = ''
  public jigHashIdsSet: Set<string> | null = null
  public jigTransferResults: any[] = []
  public transferToTenantID: number = TenantHelpers.InvalidTenantID
  public desiredOwnerEmail: string = ''
  public jigHashIdRules !: any
  public emailRules !: any
  public numberRules !: any
  public isTransfering: boolean = false
  private jigActivityTrendChart: JigActivityChartData = defaultActivityTrendChart

  public $refs!: Vue['$refs'] & {
    transferForm: any
  }

  constructor() {
    super()
  }

  protected async created() {
    this.jigHashIdRules = ValidationRules.RequiredJigHashID
    this.emailRules = ValidationRules.RequiredEmail
    this.numberRules = ValidationRules.RequiredNumber
    this.transferToTenantID = this.myTenant.ID
  }

  public get getJigActivityTrendChart(): JigActivityChartData {
    this.updateJigActivityTrendChart(this.jigDailyActivityData)
    return this.jigActivityTrendChart
  }

  public clearTransferForm() {
    this.resetTransferData()
    this.jigTransferResults.length = 0
  }

  private resetTransferData() {
    this.$refs.transferForm.reset()
    if (this.jigHashIdsSet != null && this.jigHashIdsSet.size) {
      this.jigHashIdsSet.clear()
    }
  }

  // support manual fetch rather than automatic on page opening fetching
  public async fetchJigActivityDataClick() {
    let today = new Date()
    let startDate = new Date()
    startDate.setDate(today.getDate() - 31)
    let endDate = new Date()
    endDate.setDate(today.getDate() - 1)

    const req: ReportRequest = {
      Name: AppConst.Report.MostPopularJig,
      JigHashId: "",
      Limit: Top5,
      LookBackDays,
      StartDate: startDate.toISOString(),
      EndDate: endDate.toISOString(),
      ComparePreviousPeriod,
    }

    this.isFetchingJigData = true
    await this.executeStatisticReport(req)
    this.isFetchingJigData = false
  }

  private updateJigActivityTrendChart(jigSummaryComparisonStats?: JigSummaryStats[]) {
    if (jigSummaryComparisonStats == null) {
      return
    }

    this.jigActivityTrendChart.data.datasets.length = 0
    this.jigActivityTrendChart.data.labels.length = 0

    let maxTotal = 0
    const labels: string[] = []

    // for each Jig capture a series of date (day) view totals.
    const jigActivitySeriesData: Map<number, number[]> = new Map<number, number[]>()

    // get a unique list of jig names.
    const jigNames: Map<number, string> = new Map<number, string>()

    jigSummaryComparisonStats.forEach((item: {
      id: number
      statisticDate: string
      application: string
      jigId: number
      jigName: string
      activity: string
      total: number
      durationSeconds: number
      duration: string
    }) => {
      maxTotal = Math.max(maxTotal, item.total)
      const sd = new Date(item.statisticDate)
      if (jigActivitySeriesData.has(item.jigId)) {
        const s = jigActivitySeriesData.get(item.jigId)
        if (s) {
          // add another activity counter onto this Jig series.
          s.push(item.total)
        }
      } else {
        jigActivitySeriesData.set(item.jigId, [item.total])
      }

      // keep a list of unique jig names
      if (!jigNames.has(item.jigId)) {
        jigNames.set(item.jigId, item.jigName)
      }

      // create month + date labels
      labels.push(sd.toLocaleString('en-US', { month: 'short' }) + '/' + sd.getDate().toString())
    })

    this.jigActivityTrendChart.data.labels = labels

    // for each jig activity...
    let i = 0
    jigActivitySeriesData.forEach((statisticData: number[], jigId: number) => {
      const jigName = jigNames.get(jigId) || 'Jig name not set'
      this.jigActivityTrendChart.data.datasets.push(
        {
          label: jigName,
          backgroundColor: ColorSet[i % ColorSet.length],
          data: statisticData,
        },
      )
      i++
    })
  }

  public async transferJig() {
    this.isTransfering = true
    this.jigTransferResults.length = 0
    if (!this.transferJigFormIsValid || this.jigHashIds.length === 0 || this.transferToTenantID === TenantHelpers.InvalidTenantID) {
      this.isTransfering = false
      return
    }

    this.jigHashIdsSet = new Set(this.jigHashIds.split(','))

    for (const jigHashId of this.jigHashIdsSet) {
      const req: ChangeJigOwnerRequest = {
        JigId: jigHashId,
        DesiredOwnerEmail: this.desiredOwnerEmail,
        DesiredOwnerTenantID: this.transferToTenantID,
        action: 'transfer_ownership',
      }

      const res = await this.changeJigOwner(req)

      if (res.isSuccessful) {
        this.jigTransferResults.push({
          ...this.changeJigOwnerResult,
          isSuccessful: res,
          id: jigHashId,
          toEmail: this.desiredOwnerEmail,
        })
      } else if (!res.isSuccessful && res.terminate) {
        // Terminate all requests since it's either target Tenant ID incorrect or target Email incorrect
        break
      } else {
        this.jigTransferResults.push({ isSuccessful: res.isSuccessful, id: jigHashId, message: 'Incorrect Jig ID.' })
      }

      await new Promise(resolve => setTimeout(() => resolve(true), 1000))
    }

    this.resetTransferData()
    this.isTransfering = false
  }
}
