export const ColorSet: string[] = [
  '#66c2a5',
  '#1b6caf',
  '#fc8d62',
  '#8da0cb',
  '#e78ac3',
  '#a6d854',
  '#ffd92f',
  '#e5c494',
  '#b3b3b3',
  '#37268a',
  '#4d4648',
  '#cf980f',
  '#9e4127',
  '#153644',
  '#699fc0',
]
